/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Úver pre firmu"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Úver pre firmu"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"obsah"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Financovanie určené pri firmy, ktoré nemajú možnosť ručiť nehnuteľnosťou. Firmám vieme pomôcť získať finančné prostriedky vo výške od 1.000 € do 30.000€, v priebehu 48 hodín. Celý proces zvykne prebiehať online ale v prípade záujmu, je možné si dohodnúť aj osobné stretnutie na našej pobočke.<br>Za vybavenie žiadosti a sprostredkovanie úveru, neplatíte žiadne poplatky.<br>Finálna výška úveru, výška úrokovej sadzby ako aj mesačnej splátky, bude známe po posúdení Vašej žiadosti.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"qjqps6ef6y"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Žiadosť o firemné financovanie"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":740}} content={"Vyplnenie formulára, Váš nezaväzuje k uzatvoreniu zmluvy. Slúži na preverenie možností financovania a zaslania úverovej ponuky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Požadovaná výška úveru","type":"select","options":["1000","2000","3000","4000","5000","6000","7000","8000","9000","10000","11000","12000","13000","14000","15000","16000","17000","18000","19000","20000","21000","22000","23000","24000","25000","26000","27000","28000","29000","30000"],"required":true,"placeholder":"Vyberte"},{"name":"IČO","type":"text","required":true,"placeholder":"IČO"},{"name":"Názov firmy","type":"text","required":true,"placeholder":"Názov firmy"},{"name":"Začiatok podnikania","type":"text","required":true,"placeholder":"Začiatok podnikania"},{"name":"Hrubý mesačný príjem za minulý rok","type":"text","required":true,"placeholder":"Hrubý mesačný príjem za minulý rok"},{"name":"Máte podlžnosti voči štátnym inštitúciam?","type":"select","options":["Áno","Nie"],"required":true,"placeholder":"Vyberte odpoveď"},{"name":"Meno a priezvisko","type":"text","required":true,"placeholder":"Meno a priezvisko"},{"name":"Kontaktná adresa","type":"text","required":true,"placeholder":"Kontaktná adresa"},{"name":"Tel. kontakt","type":"text","required":true,"placeholder":"+421"},{"name":"E-mail","type":"email","required":true,"placeholder":"E-mail"},{"name":"Súhlasím so spracovaním osobných údajov na účely vypracovania nezáväznej ponuky o úver ","type":"checkbox","required":true},{"name":"Odoslať žiadosť","type":"submit","align":""}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g943z5 pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Z vaší nemovitosti vytěžíme nejvíce</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style='color: var(--color-dominant)'>Ing. Tomáš Kamenovský</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style='color: var(--color-dominant)'>2023 – Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}